import React from 'react';

const Questions = ({ className = '' }) => {
  return (
    <svg className={className} width="16" height="16" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          d="M8 0C3.56363636 0 0 3.56363636 0 8c0 4.4363636 3.56363636 8 8 8 4.4363636 0 8-3.5636364 8-8 0-4.43636364-3.5636364-8-8-8zm0 14.5454545c-3.63636364 0-6.54545455-2.9090909-6.54545455-6.5454545 0-3.63636364 2.90909091-6.54545455 6.54545455-6.54545455 3.6363636 0 6.5454545 2.90909091 6.5454545 6.54545455 0 3.6363636-2.9090909 6.5454545-6.5454545 6.5454545z"
          fill="#190D33"
        />
        <path
          d="M8.8352611 3.81644771c-1.5091474-.51486958-3.16202312.29421119-3.66507225 1.76526712-.07186416.44137873.07186416.88263356.50304913.95618636.35932081.14710559.79050578-.0735528.9342341-.44137873.28745665-.73552797 1.07796243-1.17684475 1.86846821-.88263356.57491329.22065839.9342341.73552796.9342341 1.39750313 0 .73552797-1.22169075 1.32395034-1.65287572 1.54460873-.35932081.14710559-.57491329.51486958-.43118497.95618636.07186416.29421118.35932081.51486957.64677746.51486957.07186416 0 .14372832 0 .21559248-.07355279.28745665-.0735528 2.65897396-.95618636 2.65897396-2.86855907-.0718641-1.32395034-.86236991-2.42724229-2.0121965-2.86855907z"
          fill="#190D33"
        />
        <path
          d="M7.41016949 11.0711864c-.14915254.1491526-.22372881.2983051-.22372881.5220339 0 .2237289.07457627.3728814.22372881.5220339.14915254.1491526.29830509.2237289.5220339.2237289.22372881 0 .37288136-.0745763.5220339-.2237289.14915254-.1491525.22372881-.3728813.22372881-.5220339 0-.1491525-.07457627-.3728813-.22372881-.5220339-.29830509-.298305-.74576271-.298305-1.0440678 0z"
          fill="#000"
        />
      </g>
    </svg>
  );
};
export default Questions;
