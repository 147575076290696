import React from 'react';
import { Section } from 'src/components/Section';
import Layout from 'src/components/Layout';
import sizes from 'src/style/sizes';
import { mediaQueries } from 'src/style/breakpoints';
import { TopbarStaticSpace } from 'src/components/Topbar';
import { FormHeader } from 'src/components/Form';
import { FormSteps } from 'src/components/ContactUs';

const ContactPage = ({ location }) => {
  const [step, setStep] = React.useState(1);

  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C|B2B"
      pageName="Contactez-nous"
      pageType="form"
      formStart={true}
      formType="contact"
      metaTitle="Contactez-nous | Le Reacteur"
      metaDescription="Contactez-nous pour nous poser toutes vos questions sur nos formations."
    >
      <Section
        theme="primary"
        css={{
          [mediaQueries.phoneOnly]: {
            height: sizes.purpleBgHeightMobile,
          },
          [mediaQueries.tabletPortraitUp]: {
            height: sizes.purpleBgHeightTablet,
          },
          [mediaQueries.desktopUp]: {
            height: sizes.purpleBgHeightDesktop,
          },
        }}
      />
      <TopbarStaticSpace />
      <Section theme="secondary">
        <div
          css={{
            [mediaQueries.phoneOnly]: {
              marginTop: -160,
            },
            [mediaQueries.tabletPortraitUp]: {
              marginTop: -370,
            },
            [mediaQueries.tabletLandscapeUp]: {
              marginTop: -330,
            },
            [mediaQueries.desktopUp]: {
              marginTop: sizes.marginTopContainer,
            },
          }}
        >
          <FormHeader mainTitle="Nous contacter" />
          <FormSteps step={step} setStep={setStep} />
        </div>
      </Section>
    </Layout>
  );
};

export default ContactPage;
