import React, { useContext, useEffect } from 'react';
import { LeReacteurContext } from '../../Layout';
import { Paragraph } from 'src/components/Typography';
import { navigate } from 'gatsby';
import { NextBtn } from '../../Form/elements';

const FinalBlock = ({ onFormSuccess }) => {
  const { dtlFormValidation } = useContext(LeReacteurContext);

  useEffect(() => {
    if (window) {
      window.scrollTo({
        top: 90,
        left: 0,
        behavior: 'smooth',
      });
    } else {
      return null;
    }
  });
  useEffect(() => {
    dtlFormValidation('contact');
  }, []);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 330,
      }}
    >
      <Paragraph css={{ textAlign: 'center' }}>
        Merci pour votre message.
        <br />
        Nous reviendrons vers vous dans les plus brefs délais.
      </Paragraph>

      <NextBtn
        onClick={() => {
          navigate('/');
          onFormSuccess();
        }}
      >
        Fermer
      </NextBtn>
    </div>
  );
};

export default FinalBlock;
