import React from 'react';
import { FormBlock, FinalBlock } from './index';
import { FormBlockSection } from 'src/components/Form/elements';
import { useRestoreFormManager } from 'src/utils/RestoreForm';
import { mediaQueries } from 'src/style/breakpoints';

const FORM_NAME = 'contact-form-completed';

const FormSteps = ({ step, setStep }) => {
  const manager = useRestoreFormManager();

  return (
    <FormBlockSection
      css={{
        padding: '0 30px 35px 30px',
        [mediaQueries.tabletLandscapeUp]: {
          padding: '0 60px 55px 60px',
        },
      }}
    >
      {step === 1 ? (
        <FormBlock
          onFormSuccess={() => {
            manager.set(FORM_NAME, true);
            setStep(2);
          }}
        />
      ) : (
        step === 2 && (
          <FinalBlock
            onFormSuccess={() => {
              manager.reset('contact-form');
              manager.reset('contact-form-completed');
            }}
          />
        )
      )}
    </FormBlockSection>
  );
};

export default FormSteps;
