const ContactFormSubjects = [
  { value: 'Formations', label: 'Formations' },
  { value: 'Formations Professionnelles', label: 'Formations Professionnelles' },
  { value: 'Alternance', label: 'Alternance' },
  { value: 'Partenariats', label: 'Partenariats' },
  { value: 'Recrutement', label: 'Recrutement' },
  { value: 'Réclamation', label: 'Réclamation' },
  { value: 'RGPD', label: 'RGPD' },
  {
    value: "Développement d'un site Web ou d'une application Mobile",
    label: "Développement d'un site Web ou d'une application Mobile",
  },
  { value: 'Autre', label: 'Autre' },
];

export default ContactFormSubjects;
